import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Polyline} from 'react-leaflet';
import GpxParser from 'gpxparser';
import '/src/theme/styles.css';
import styled from 'styled-components';

const StyledMapContainer = styled(MapContainer)`
  .leaflet-control-attribution.leaflet-control {
    display: none;
  }
`;

type TrackPoint = [number, number]; // [latitude, longitude]

const calculateCenter = (trackPoints: TrackPoint[]): TrackPoint => {
    const lats = trackPoints.map((point) => point[0]);
    const lons = trackPoints.map((point) => point[1]);

    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLon = Math.min(...lons);
    const maxLon = Math.max(...lons);

    return [(minLat + maxLat) / 2, (minLon + maxLon) / 2];
};

const readZoomLevel = (filename: string) => {
    const zoomSubstring = filename.match(/_z(\d+)/);
    if (zoomSubstring) {
        return parseFloat(zoomSubstring[1])
    } else {
        return 6; // Default zoom level if no _zoom-* substring found
    }
};

const MapPolylines = ({trackPoints}: { trackPoints: [number, number][] }) => {
    return (
        <>
            <Polyline positions={trackPoints} color="#f2efe9" weight={10}/>
            <Polyline positions={trackPoints} color="#000000" weight={5}/>
        </>
    );
};

const MapContainerInstance: React.FC<{
    trackPoints: TrackPoint[];
    centerCoordinate: TrackPoint;
    zoomLevel: number;
}> = ({trackPoints, centerCoordinate, zoomLevel}) => {

    // Specify the source of our map tiles
    // const apiKey = process.env.GATSBY_STADIA_MAPS_API_KEY
    // const query = apiKey ? `?api_key=${apiKey}` : ``;
    // const tileUrl = `https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}.png` + query;
    const tileUrl = `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`

    return (
        <StyledMapContainer
            style={{height: '100vh'}}
            center={centerCoordinate}
            zoom={zoomLevel}
            zoomControl={false} // Disable the default zoom control
            key={new Date().getTime()} // <-- Add key prop to force re-render
        >
            <TileLayer url={tileUrl}/>
            {trackPoints.length > 0 && <MapPolylines trackPoints={trackPoints}/>}
        </StyledMapContainer>
    );
};


interface MapContext {
    pageContext: {
        gpx_file: string;
    }
}

const MapTemplate: React.FC<MapContext> = ({pageContext}) => {
    // Unpack the GPX file from the page context
    const {gpx_file} = pageContext;

    // Create a useState to collect GPX track coordinates
    const [trackPoints, setTrackPoints] = useState([]);

    // Fetch the GPX file and extract track points
    useEffect(() => {
        const fetchData = async () : Promise<void> => {
            // Fetch the GPX file from the publicURL
            const response = await fetch(gpx_file);
            // Extract text data from the fetched response
            const gpxData = await response.text();
            // Create a new parser instance and parse the text data to coordinates
            const gpx = new GpxParser();
            gpx.parse(gpxData);
            // Assuming we have a single track in your GPX file
            const track = gpx.tracks[0];
            // Extracting the track points (latitudes and longitudes)
            const trackPoints = track.points.map((point) => [point.lat, point.lon]);
            // Update our collection of track coordinates
            setTrackPoints(trackPoints);
        };
        fetchData().then(); // fetchData when the component mounts
    }, []);

    // Read the zoom level from the GPX publicURL
    const zoomLevel = readZoomLevel(gpx_file);

    // Calculate the center of the track points
    const centerCoordinate = trackPoints.length ? calculateCenter(trackPoints) : [50.0, 10.0];

    // Created the map
    return (
        <MapContainerInstance
            trackPoints={trackPoints}
            centerCoordinate={centerCoordinate}
            zoomLevel={zoomLevel}
        />
    );
};

export default MapTemplate;
